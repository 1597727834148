import type { AllComplianceDTO } from '@orus.eu/backend/src/compliance/routers/compliance.dto'
import { isSuccess } from '@orus.eu/result'
import type { ComplianceData } from '../detailed-sheet.types'

export const getComplianceData = (data: AllComplianceDTO | undefined): ComplianceData => {
  if (data) {
    const { complyAdvantageCustomersCase, pappersCompany, lystaCompany } = data

    const complyAdvantageCustomersCaseSuccessOutput = complyAdvantageCustomersCase
      .filter((customerCase) => isSuccess(customerCase))
      .map((customerCase) => customerCase.output)

    return {
      pappersCompany: isSuccess(pappersCompany) ? pappersCompany.output : undefined,
      lystaCompany: isSuccess(lystaCompany) ? lystaCompany.output : undefined,
      complyAdvantageCustomersCase:
        complyAdvantageCustomersCaseSuccessOutput.length > 0 ? complyAdvantageCustomersCaseSuccessOutput : [],
    }
  }

  return {
    pappersCompany: undefined,
    lystaCompany: undefined,
    complyAdvantageCustomersCase: [],
  }
}
