import styled from '@emotion/styled'
import type { TimelineTopLevelEvent } from '@orus.eu/backend/src/modules/timeline/timeline-view'
import { SidePanel, spacing } from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import type { ReactElement } from 'react'
import { useCallback } from 'react'
import { trpcReact } from '../../../../../client'
import { sendMessage } from '../../../../../lib/tracking/tracking'
import { TimelineEvents } from './TimelineEvents'
import { TimelineForm } from './TimelineForm'

type TimelineProps = {
  subscriptionId: string
  timelineId: string | undefined
  threadId: string | undefined
  tabId: string | undefined
  timelineHeight: string
}

export const Timeline = function Timeline(props: TimelineProps): ReactElement {
  const { subscriptionId, timelineId, threadId, tabId, timelineHeight } = props
  const navigate = useNavigate()

  const timelineQuery = trpcReact.timeline.getTimeline.useQuery(
    timelineId
      ? { type: 'id', id: timelineId }
      : {
          type: 'business_object',
          id: {
            type: 'contract_version',
            id: {
              type: 'initial',
              id: subscriptionId,
            },
          },
        },
    {
      refetchInterval: 10_000,
    },
  )

  const isThreadOpen = !!threadId
  const openThread = useCallback(
    (eventId: string) => {
      if (!timelineQuery.data) return

      sendMessage({
        event: 'timeline_thread_opened',
        timeline_id: timelineQuery.data.id,
      })

      void navigate({
        search: {
          // @ts-expect-error typing error
          tab_id: tabId,
          timeline_id: timelineQuery.data.id,
          thread_id: eventId,
        },
      })
    },
    [navigate, timelineQuery.data, tabId],
  )
  const closeThread = useCallback(() => {
    if (!timelineQuery.data) return

    sendMessage({
      event: 'timeline_thread_closed',
      timeline_id: timelineQuery.data.id,
    })

    void navigate({
      search: {
        // @ts-expect-error typing error
        tab_id: tabId,
        timeline_id: timelineQuery.data.id,
        thread_id: undefined,
      },
    })
  }, [navigate, timelineQuery.data, tabId])

  const timeline = timelineQuery.data?.timeline
  const resolvedTimelineId = timelineQuery.data?.id

  const parentEvent = timeline?.find((event: TimelineTopLevelEvent) => event.id === threadId)

  return (
    <StyledTab height={timelineHeight}>
      <StyledTimelineWrapper isThreadOpen={isThreadOpen}>
        <StyledTimelineContainer>
          {timeline ? (
            <TimelineEvents
              isThread={false}
              data={timeline}
              onReplyOnThread={openThread}
              timelineQuery={timelineQuery}
            />
          ) : null}
          {resolvedTimelineId ? (
            <TimelineForm isThread={false} timelineQuery={timelineQuery} timelineId={resolvedTimelineId} />
          ) : null}
        </StyledTimelineContainer>
      </StyledTimelineWrapper>
      {isThreadOpen ? (
        <StyledThreadWrapper height={timelineHeight}>
          <SidePanel
            title="Fil de discussion"
            hasCloseButton={true}
            isSidePanelOpen={isThreadOpen}
            setIsSidePanelOpen={(threadId?: string) => threadId && openThread(threadId)}
            setIsSidePanelClose={closeThread}
          >
            {parentEvent ? (
              <TimelineEvents
                isThread={true}
                data={parentEvent.children}
                parentEvent={parentEvent}
                timelineQuery={timelineQuery}
              />
            ) : null}
            {timelineId ? (
              <TimelineForm isThread={true} timelineQuery={timelineQuery} threadId={threadId} timelineId={timelineId} />
            ) : null}
          </SidePanel>
        </StyledThreadWrapper>
      ) : null}
    </StyledTab>
  )
}

const StyledTab = styled.div<{ height: string }>`
  display: flex;
  position: relative;
  height: ${({ height }) => height};
`

const StyledTimelineWrapper = styled.div<{ isThreadOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: center;
  margin-top: ${spacing['60']};
  flex-grow: 1;
  overflow-y: scroll;
`

const StyledThreadWrapper = styled.div<{ height: string }>`
  position: sticky;
  top: 0;
  right: 0;
  height: ${({ height }) => height};
  overflow-y: scroll;
`

const StyledTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  flex-grow: 1;
`
