import type { TimelineChildEvent, TimelineTopLevelEvent } from '@orus.eu/backend/src/modules/timeline/timeline-view'

export function getAnswer(event: TimelineTopLevelEvent): {
  authorEmails: string[]
  answersCount: number
  lastAnswerDate: Date
} | null {
  if (event.children.length === 0) {
    return null
  }

  return {
    authorEmails: getFirstThreeUniqueAvatar(event.children),
    answersCount: event.children.length,
    lastAnswerDate: event.children[event.children.length - 1].date,
  }
}

export function getFirstThreeUniqueAvatar(arr: TimelineChildEvent[]): string[] {
  const authors = arr
    .filter((item: TimelineChildEvent) => item.author.type === 'user')
    .map((item: TimelineChildEvent) => {
      return item.author
    })
  const emails = authors.map((author) => ('email' in author ? author.email : ''))
  const seen = new Set(emails)

  return [...seen].slice(0, 3)
}
