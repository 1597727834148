import styled from '@emotion/styled'
import type {
  TimelineChildEvent,
  Timeline as TimelineInterface,
  TimelineTopLevelEvent,
} from '@orus.eu/backend/src/modules/timeline/timeline-view'
import type { ORUS_TRPC_ERROR_CODE } from '@orus.eu/backend/src/types/orus-custom-error'
import { HorizontalDivider, TimelineEvent, spacing, useEnqueueToast } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import type { TRPCClientErrorLike } from '@trpc/client'
import type { UseTRPCQueryResult } from '@trpc/react-query/dist/shared'
import type { TRPC_ERROR_CODE_NUMBER } from '@trpc/server/unstable-core-do-not-import'
import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { trpcReact } from '../../../../../client'
import { useSession } from '../../../../../lib/session'
import { sendMessage } from '../../../../../lib/tracking/tracking'
import { getAnswer } from './helpers/get-answer'
import { timelineEventDefinitions } from './timeline-event.definition'

type TimelineEventsProps = {
  isThread: boolean
  data: TimelineChildEvent[] | TimelineTopLevelEvent[]
  onReplyOnThread?: (threadId: string) => void
  parentEvent?: TimelineTopLevelEvent
  timelineQuery: UseTRPCQueryResult<
    {
      id: string
      timeline: TimelineInterface
    },
    TRPCClientErrorLike<{
      input: {
        type: 'subscription' | 'timeline' | 'collected_file'
        id: string
      }
      output: {
        id: string
        timeline: TimelineInterface
      }
      transformer: true
      errorShape: {
        data: {
          code: ORUS_TRPC_ERROR_CODE
          httpStatus: number
          path?: string
          stack?: string
        }
        message: string
        code: TRPC_ERROR_CODE_NUMBER
      }
    }>
  >
}

export const TimelineEvents = function TimelineEvents(props: TimelineEventsProps): ReactElement {
  const { isThread, data, onReplyOnThread, parentEvent, timelineQuery } = props

  const { enqueueToast } = useEnqueueToast()
  const session = useSession()

  const deleteCommentMutation = trpcReact.timeline.deleteComment.useMutation({
    onSuccess: () => timelineQuery.refetch(),
  })

  useEffect(() => {
    const result = deleteCommentMutation.data

    if (!result) return

    if (isFailure(result)) {
      switch (result.problem) {
        case 'not_found':
          enqueueToast("Ce commentaire n'existe pas", {
            variant: 'warning',
          })
          return
        case 'too_old':
          enqueueToast('Ce commentaire est trop ancien pour être supprimé', {
            variant: 'warning',
          })
          return
        case 'not_allowed':
          enqueueToast("Vous n'avez pas les permissions pour supprimer ce commentaire", {
            variant: 'warning',
          })
          return
      }

      // It's not actually unreachable because the backend might be ahead of the frontend
      enqueueToast('Une erreur est survenue lors de la suppression du commentaire', {
        variant: 'warning',
      })
    }
  }, [deleteCommentMutation.data, enqueueToast])

  return (
    <StyledTimelineEvents isThread={isThread}>
      {isThread && parentEvent ? (
        <>
          <TimelineEvent
            key={parentEvent.id}
            type={parentEvent.type}
            size={isThread ? 'small' : 'large'}
            variant={
              parentEvent.type === 'event' ? timelineEventDefinitions[parentEvent.event.type].variant : undefined
            }
            icon={parentEvent.type === 'event' ? timelineEventDefinitions[parentEvent.event.type].icon : undefined}
            author={parentEvent.author.type === 'user' ? parentEvent.author.email : undefined}
            date={parentEvent.date}
            answer={null}
            isDeleted={parentEvent.type === 'comment' ? parentEvent.deleted : undefined}
            attachment={
              parentEvent.type === 'event' && parentEvent.event.type === 'file_collected'
                ? {
                    name: parentEvent.event.collectedFileName,
                    onClick: null,
                  }
                : undefined
            }
          >
            {parentEvent.type === 'event'
              ? timelineEventDefinitions[parentEvent.event.type].labelBuilder(
                  // @ts-expect-error ts is not able to infer the type of the event
                  parentEvent.event,
                )
              : parentEvent.type === 'comment' && !parentEvent.deleted
                ? parentEvent.body.text
                : null}
          </TimelineEvent>
          {data.length > 0 ? <HorizontalDivider /> : null}
        </>
      ) : null}
      {data.map((event) => {
        const eventDef = event.type === 'event' ? timelineEventDefinitions[event.event.type] : undefined
        return (
          <TimelineEvent
            key={event.id}
            type={event.type}
            size={isThread ? 'small' : 'large'}
            variant={eventDef?.variant}
            icon={eventDef?.icon}
            author={event.author.type === 'user' ? event.author.email : undefined}
            date={event.date}
            onReplyOnThread={
              onReplyOnThread
                ? () => {
                    onReplyOnThread?.(event.id)
                  }
                : undefined
            }
            answer={!isThread && 'children' in event ? getAnswer(event) : null}
            attachment={
              event.type === 'event' && event.event.type === 'file_collected'
                ? {
                    name: event.event.collectedFileName,
                    onClick: null,
                  }
                : undefined
            }
            isDeleted={event.type === 'comment' ? event.deleted : undefined}
            onDelete={
              event.type === 'comment' &&
              !event.deleted &&
              event.author.type === 'user' &&
              session.user?.id === event.author.userId
                ? () => {
                    sendMessage({
                      event: 'timeline_comment_deleted',
                      timeline_id: event.id,
                    })
                    deleteCommentMutation.mutate({ eventId: event.id })
                  }
                : undefined
            }
          >
            {event.type === 'event' && eventDef
              ? eventDef.labelBuilder(
                  // @ts-expect-error ts is not able to infer the type of the event
                  event.event,
                )
              : event.type === 'comment' && !event.deleted
                ? event.body.text
                : null}
          </TimelineEvent>
        )
      })}
      <ScrollAnchor />
    </StyledTimelineEvents>
  )
}

const StyledTimelineEvents = styled.div<{ isThread: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${spacing['40']};
  margin-top: ${({ isThread }) => (isThread ? spacing['40'] : '0')};

  * {
    overflow-anchor: none;
  }
`

const ScrollAnchor = styled.div`
  overflow-anchor: auto;
  height: 1px;
`
