import styled from '@emotion/styled'
import type { Timeline } from '@orus.eu/backend/src/modules/timeline/timeline-view'
import type { ORUS_TRPC_ERROR_CODE } from '@orus.eu/backend/src/types/orus-custom-error'
import {
  borderStroke,
  Button,
  colorTokens,
  cssPropsPerScreenVariantPerTextVariant,
  shadow,
  spacing,
  useAsyncCallback,
  useEnqueueToast,
} from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import type { TRPCClientErrorLike } from '@trpc/client'
import type { UseTRPCQueryResult } from '@trpc/react-query/dist/shared'
import type { TRPC_ERROR_CODE_NUMBER } from '@trpc/server/unstable-core-do-not-import'
import { useState, type FormEvent, type ReactElement } from 'react'
import { trpcReact } from '../../../../../client'
import { sendMessage } from '../../../../../lib/tracking/tracking'

export type TimelineFormProps = {
  isThread: boolean
  threadId?: string
  timelineQuery: UseTRPCQueryResult<
    {
      id: string
      timeline: Timeline
    },
    TRPCClientErrorLike<{
      input: {
        type: 'subscription' | 'timeline' | 'collected_file'
        id: string
      }
      output: {
        id: string
        timeline: Timeline
      }
      transformer: true
      errorShape: {
        data: {
          code: ORUS_TRPC_ERROR_CODE
          httpStatus: number
          path?: string
          stack?: string
        }
        message: string
        code: TRPC_ERROR_CODE_NUMBER
      }
    }>
  >
  timelineId: string
}

export const TimelineForm = function TimelineForm(props: TimelineFormProps): ReactElement {
  const { isThread, threadId, timelineQuery, timelineId } = props

  const { enqueueToast } = useEnqueueToast()
  const [comment, setComment] = useState('')

  const addCommentMutation = trpcReact.timeline.publishComment.useMutation({
    onSuccess: () => timelineQuery.refetch(),
  })

  const handleSubmit = useAsyncCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const commentText = comment.trim()

      if (!commentText || (isThread && !threadId)) return

      sendMessage({
        event: 'timeline_comment_published',
        timeline_id: timelineId,
        in_thread: isThread,
      })

      const result = await addCommentMutation.mutateAsync({
        target: {
          timelineId: timelineId,
          ...(isThread && threadId ? { type: 'event', topLevelEventId: threadId } : { type: 'top_level' }),
        },
        body: { type: 'text', text: commentText },
      })

      if (isFailure(result)) {
        enqueueToast("Erreur lors de l'envoi de votre commentaire", {
          variant: 'danger',
        })
      }

      setComment('')
    },
    [addCommentMutation, comment, enqueueToast, isThread, threadId, timelineId],
  )

  const isDirty = comment.trim() !== ''

  return (
    <StyledFormWrapper>
      <StyledForm onSubmit={handleSubmit}>
        <StyledFormTextarea
          name="comment"
          placeholder="Ecrire un commentaire"
          disabled={addCommentMutation.isPending}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              e.currentTarget.form?.requestSubmit()
            }
          }}
        />
        <StyledFormButtonGroup>
          <Button
            type="submit"
            size="small"
            icon="arrow-up-regular"
            disabled={!isDirty}
            isLoading={addCommentMutation.isPending}
          />
        </StyledFormButtonGroup>
      </StyledForm>
    </StyledFormWrapper>
  )
}

const StyledFormWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background: ${colorTokens['color-fg-base-primary']};
  padding: ${spacing['60']} 1px ${spacing['80']};
`

const StyledForm = styled.form`
  display: flex;
  padding: ${spacing['40']};
  flex-direction: column;
  gap: ${spacing['30']};
  border-radius: ${spacing['30']};
  border: ${borderStroke['20']} solid ${colorTokens['color-stroke-base']};
  box-shadow: ${shadow['bottom']['05']};
`

const StyledFormTextarea = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  color: ${colorTokens['color-text-base-basic']};
  ${cssPropsPerScreenVariantPerTextVariant['input']['desktop']}

  &:disabled {
    color: ${colorTokens['color-text-base-disable']};
    background: ${colorTokens['color-fg-base-primary']};
  }

  &::placeholder {
    color: ${colorTokens['color-text-base-disable']};
  }
`

const StyledFormButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
