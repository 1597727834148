import { amountToString } from '@orus.eu/amount'
import type { MapDiscriminatedUnion } from '@orus.eu/backend/src/lib/type-utils'
import type { TimelineEventEvent } from '@orus.eu/backend/src/modules/timeline/timeline-view'
import type { CompoundIconName, TimelineEventVariant } from '@orus.eu/pharaoh'

export const timelineEventDefinitions: {
  [type in TimelineEventEvent['event']['type']]: {
    variant: TimelineEventVariant
    icon: CompoundIconName
    labelBuilder: (event: MapDiscriminatedUnion<TimelineEventEvent['event'], 'type'>[type]) => string
  }
} = {
  subscription_created: {
    variant: 'neutral',
    icon: 'circle-plus-regular',
    labelBuilder: () => 'a commencé le devis',
  },
  quote_updated: {
    variant: 'neutral',
    icon: 'arrows-rotate-regular',
    labelBuilder: (event) =>
      `a mis à jour le devis et a obtenu le prix de ${amountToString(event.annualizedTotal, { addCurrency: true })}`,
  },
  file_collected: {
    variant: 'neutral',
    icon: 'file-arrow-down-regular',
    labelBuilder: () => 'a ajouté un document',
  },
  contract_signed: {
    variant: 'success',
    icon: 'square-check-regular',
    labelBuilder: () => 'a signé le contrat',
  },
  endorsement_signed: {
    variant: 'success',
    icon: 'square-check-regular',
    labelBuilder: () => "a signé l'avenant",
  },
  contract_renewed: {
    variant: 'neutral',
    icon: 'arrows-rotate-regular',
    labelBuilder: () => 'a renouvelé le contrat',
  },
}
